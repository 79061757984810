import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    // 首页
    {
        path: '/',
        name: 'index',
        redirect: '/',
        component: (resolve) => require(['@/views/index'], resolve),
        children: [{
                path: '/',
                name: 'home',
                component: (resolve) => require(['@/views/pages/home'], resolve),
            },
            {
                path: '/about',
                name: 'about',
                component: (resolve) =>
                    require(['@/views/pages/about'], resolve),
            },
            {
                path: '/help',
                name: 'help',
                component: (resolve) =>
                    require(['@/views/pages/help'], resolve),
            },
            {
                path: '/product',
                name: 'product',
                component: (resolve) =>
                    require(['@/views/pages/product'], resolve),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history", //去掉url中的#
    routes
});

export default router;