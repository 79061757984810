import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// require("@/utils/talkIframe")
import {
    Button,
    Input,
    Message,
    MessageBox,
    TabPane,
    Tabs,
    Carousel,
    CarouselItem,
    Loading,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tooltip
} from 'element-ui'

import './assets/style/basic.scss'
import './assets/style/element-variable.scss'
Vue.use(Button)
    .use(Input)
    .use(TabPane)
    .use(Tabs)
    .use(Carousel)
    .use(CarouselItem)
    .use(Loading.directive)
    .use(Dropdown)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Tooltip)
Vue.component(Message)
Vue.component(MessageBox)
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$loading = Loading.service
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')